import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Seo } from '../components/seo';

export default function IndexPage({ data }) {
  let { nodes } = data.allMdx;
  nodes.sort((a, b) => a.frontmatter.order - b.frontmatter.order);

  return (
    <Layout>
      <Seo />
      <div className="mb-10">
        <h1 className="mt-0 mb-2">MultiMedia Projekte</h1>
        <p>Projekte in den Studiengängen MultiMediaTechnology (MMT) und MultiMediaArt (MMA).</p>
      </div>
      <ul className="list-none flex flex-wrap gap-4 justify-center ">
        {nodes.map((page) => (
          <li
            key={page.slug}
            className="flex flex-row place-content-between w-full min-h-fit shadow-md transition duration-300 rounded-md cursor-pointer  bg-white dark:bg-gray-900 dark:border-gray-900 "
          >
            <a
              href={`/${page.slug}`}
              className="flex grow max-w-2xl flex-col no-underline py-3 px-4 h-full rounded-md  border-2 border-white hover:border-yellow-500"
            >
              <h2 className="text-lg mb-1">{page.frontmatter.title}  <small>{page.frontmatter.when}</small></h2>
              <p className="mb-0">{page.excerpt}</p>
            </a>
            {  (
            <a
              href={`/static/${page.slug.slice(0, -1)}.pdf`}
              className="flex flex-col block h-full w-22 basis-14 shrink-0 justify-center border-2 rounded-md border-white hover:border-yellow-500"
              ><div className="break-inside-avoid w-20"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" className="inline  "><path d="M4.97 11.03a.75.75 0 111.06-1.06L11 14.94V2.75a.75.75 0 011.5 0v12.19l4.97-4.97a.75.75 0 111.06 1.06l-6.25 6.25a.75.75 0 01-1.06 0l-6.25-6.25zm-.22 9.47a.75.75 0 000 1.5h14.5a.75.75 0 000-1.5H4.75z"></path></svg>PDF</div ></a>
            )}
          </li>
        ))}
      </ul>
    </Layout>
  );
}

export const query = graphql`
  query INDEX_QUERY {
    allMdx(filter: { frontmatter: { type: { eq: "project" }  } }) {
      nodes {
        slug
        excerpt(pruneLength: 144)
        frontmatter {
          title
          order
          when
        }
      }
    }
  }
`;
